<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col style="padding:0">
          <div class="chart-wrapper" style="" ref="chart-wrapper">
            <line-chart v-if="loaded"
                        :chart-data="datacollection"
                        :options="chartOptions"
                        :chart-id="'bajschart'"
                        :width="'100%'"
                        :height="'580px'"
                        :css-classes="''"
                        :plugins="''"
            >
            </line-chart>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="dashboad bg-light page-wrapper">
      <b-row class="main-content-wrapper" no-gutters>
        <b-col>

          <h1>ChartJS Experiment</h1>
          <p class="lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>

          <button @click="fillData()">Randomize</button>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'

export default {
  components: {
    LineChart
  },
  data () {
    return {
      datacollection: null,
      chartOptions: null,
      loaded: false
    }
  },
  async mounted () {
    this.fillData()
    this.loaded = true

    /* this.loaded = false
    try {
      const { userlist } = await fetch('/api/userlist')
      this.chartdata = userlist
      this.loaded = true
    } catch (e) {
      console.error(e)
    } */
    // this.$refs['chart-wrapper'].style.height = '600px'
    // this.$refs.canvas
  },
  methods: {
    fillData () {
      this.chartOptions = {
        title: {
          display: false,
          text: 'Custom as Title',
          fontColor: '#FFFFFF'
        },
        legend: {
          display: true,
          labels: {
            fontColor: 'rgb(0255,255,255)',
            fontFamily: 'Roboto'
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#FFFFFF', // this here
              fontFamily: 'Roboto'
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              fontColor: '#FFFFFF', // this here
              defaultFontFamily: 'Roboto'
            }
          }]
        },
        pointDot: true,
        pointDotRadius: 5,
        pointDotStrokeWidth: 1,

        onAnimationComplete: null,
        scaleLineColor: 'rgba(0,0,0,.25)',

        responsive: true,
        maintainAspectRatio: false
      }
      this.datacollection = {
        labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June'],
        datasets: [
          {
            label: 'DataSet 01',
            backgroundColor: '#FEC600',
            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
          }, {
            label: 'DataSet 02',
            backgroundColor: '#F2006B',
            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
</script>

<style>
  .chart-wrapper {
    position: relative;
    //max-width:800px;
    height:600px;
    width:100%;
    margin-top: 60px;
    padding:0;
    color: #FFFFFF;
    box-shadow: 20px 22px 44px 0 rgba(82,86,112,0.55);
  }
</style>
